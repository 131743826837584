//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import allCustomForm from '@/views/main/customForm/index.js'

export default {
  data() {
    return {
      allCustomFormComps: allCustomForm,
      formCompKey: '',
      jsonData: {},
      visible: false,
      confirmLoading: false,
      cardLoading: false
    }
  },

  methods: {
    /**
     * 初始化方法
     */
    preview(record) {
      /*  if (record.formJson === null) {
        this.$message.error('無可預覽的表單數據')
        return
      } */

      this.formCompKey = record.formUrl

      // if (!this.allCustomFormComps[this.formCompKey]) {
      //   this.$message.error('無可預覽的表單數據，請修改formUrl字段！')
      //   return
      // }

      this.jsonData = JSON.parse(record.formJson)
      this.visible = true
    },
    /**
     * 關閉界面，置空表單
     */
    handleCancel() {
      this.jsonData = {}
      this.visible = false
    }
  }
}
