//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formResourceAdd } from '@/api/modular/flowable/formResourceManage'
import { flowableCategoryList } from '@/api/modular/flowable/categoryManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage.js'

export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      flowableCategoryListData: [],
      flowableTypeListData: [],
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    add() {
      this.visible = true
      this.flowableCategoryList()
      this.getFlowableTypeList()
    },

    /**
     * 獲取分類
     */
    flowableCategoryList() {
      flowableCategoryList().then(res => {
        this.flowableCategoryListData = res.data
      })
    },

    /**
     * 獲取表單類型
     */
    getFlowableTypeList() {
      sysDictTypeDropDown({ code: 'form_resource_type' }).then(res => {
        this.flowableTypeListData = res.data
      })
    },

    handleSubmit() {
      const {
        form: { validateFields }
      } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          formResourceAdd(values).then(res => {
            this.confirmLoading = false
            if (res.success) {
              this.$message.success('新增成功')
              this.visible = false
              this.$emit('ok', values)
              this.form.resetFields()
            } else {
              this.$message.error('新增失敗：' + res.message)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false
    }
  }
}
