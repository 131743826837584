//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis, XCard } from '@/components'
import { formResourcePage, formResourceDelete } from '@/api/modular/flowable/formResourceManage'
import { flowableCategoryList } from '@/api/modular/flowable/categoryManage'
import addForm from './addForm'
import editForm from './editForm'
import designForm from './designForm'
import copyForm from './copyForm'
import preview from './preview'
export default {
  components: {
    XCard,
    Ellipsis,
    STable,
    addForm,
    editForm,
    designForm,
    copyForm,
    preview
  },
  data() {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '表單名稱',
          dataIndex: 'name'
        },
        {
          title: '唯一編碼',
          dataIndex: 'code'
        },
        {
          title: '表單分類',
          dataIndex: 'category',
          scopedSlots: { customRender: 'category' }
        },
        {
          title: '備註',
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return formResourcePage(Object.assign(parameter, this.queryParam)).then(res => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      flowableCategoryListData: [],
      indexPageShow: true,
      designFormShow: false
    }
  },
  created() {
    this.flowableCategoryList()
    if (
      this.hasPerm('flowableFormResource:design') ||
      this.hasPerm('flowableFormResource:edit') ||
      this.hasPerm('flowableFormResource:delete')
    ) {
      this.columns.push({
        title: '操作',
        width: '200px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    /**
     * 打開表單設計
     */
    openDesign(record) {
      this.indexPageShow = false
      this.designFormShow = true
      this.$refs.designForm.design(record)
    },
    /**
     * 關閉表單設計
     */
    closeDesign() {
      this.indexPageShow = true
      this.designFormShow = false
      this.$refs.table.refresh()
    },
    /**
     * 獲取分類
     */
    flowableCategoryList() {
      flowableCategoryList().then(res => {
        this.flowableCategoryListData = res.data
      })
    },
    categoryFilter(text) {
      // eslint-disable-next-line eqeqeq
      const values = this.flowableCategoryListData.filter(item => item.code == text)
      if (values.length > 0) {
        return values[0].name
      }
    },
    /**
     * 刪除
     */
    formResourceDelete(record) {
      formResourceDelete(record).then(res => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      })
    },
    /**
     * 回調事件
     */
    handleOk() {
      this.$refs.table.refresh()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
